import { CssVarsThemeOptions, extendTheme } from '@mui/joy';
import { ColorSystemOptions } from '@mui/joy/styles/extendTheme';
import { DefaultColorScheme, PaletteRange } from '@mui/joy/styles/types';
import generatePalette from './generatePalette';
import Scale, { ScaleRatio } from './scale';

const scale = Scale({
  // ratio: ScaleRatio.MajorThird,
  ratio: ScaleRatio.MinorThird,
});

const enum Typeface {
  Alegreya = 'Alegreya Variable, serif',
  Montez = 'Montez, cursive',
  SourceSans = 'Source Sans 3 Variable, sans-serif',
  Montserrat = 'Montserrat Variable, sans-serif',
  WorkSans = 'Work Sans Variable, sans-serif',
  Merriweather = 'Merriweather, serif',
  StyleScript = 'Style Script, cursive',
  Palanquin = 'Palanquin, sans-serif',
  Kanit = 'Kanit, sans-serif',
}

export const FontFamily = {
  Montserrat: 'montserrat',
  SourceSans: 'sourceSans',
  Montez: 'montez',
  Alegreya: 'alegreya',
  WorkSans: 'workSans',
  Merriweather: 'merriweather',
  StyleScript: 'styleScript',
  Palanquin: 'palanquin',
  Kanit: 'kanit',
};

export const enum Weight {
  xs = 200,
  sm = 300,
  md = 400,
  lg = 700,
  xl = 600,
}

declare module '@mui/joy/styles' {
  interface ColorPalettePropOverrides {
    secondary: true;
    tertiary: true;
  }

  interface Palette {
    secondary: PaletteRange;
    tertiary: PaletteRange;
    gradient: {
      background: string;
    };
  }

  interface TypographySystemOverrides {}

  interface FontFamilyOverrides {
    // By style…
    handwritten: true;

    // By typeface name…
    alegreya: true;
    montez: true;
    sourceSans: true;
    montserrat: true;
    workSans: true;
    merriweather: true;
    styleScript: true;
    palanquin: true;
    kanit: true;
  }
}

const typography: CssVarsThemeOptions = {
  fontFamily: {
    // By style…
    display: Typeface.Merriweather,
    body: Typeface.Merriweather,
    handwritten: Typeface.Montez,

    // By typeface name…
    [FontFamily.Alegreya]: Typeface.Alegreya,
    [FontFamily.Montez]: Typeface.Montez,
    [FontFamily.SourceSans]: Typeface.SourceSans,
    [FontFamily.Montserrat]: Typeface.Montserrat,
    [FontFamily.WorkSans]: Typeface.WorkSans,
    [FontFamily.Merriweather]: Typeface.Merriweather,
    [FontFamily.StyleScript]: Typeface.StyleScript,
    [FontFamily.Palanquin]: Typeface.Palanquin,
    [FontFamily.Kanit]: Typeface.Kanit,
  },
  lineHeight: {
    xl: '1rlh',
    lg: '1rlh',
    md: '0.8rlh',
    sm: '0.65rlh',
    xs: '0.8rlh',
  },
  fontSize: {
    xl4: scale(4),
    xl3: scale(3),
    xl2: scale(2),
    xl: scale(1),
    lg: scale(0),
    md: scale(-1),
    sm: scale(-2),
    xs: scale(-3),
  },
  spacing: (absolute: number) => `${absolute / 2}rlh`,
  fontWeight: {
    xs: Weight.xs,
    sm: Weight.sm,
    md: Weight.md,
    lg: Weight.lg,
    xl: Weight.xl,
  },
};

const primaryPalette = generatePalette(['#f8b195', '#f67280', '#6c5b7b']);
const secondaryPalette = generatePalette(['#F2E5FD', '#382847']);
const tertiaryPalette = generatePalette(['#97BBD8', '#355c7d']);

const colorSchemes: Partial<Record<DefaultColorScheme, ColorSystemOptions>> = {
  light: {
    palette: {
      primary: primaryPalette,
      secondary: secondaryPalette,
      tertiary: tertiaryPalette,

      gradient: {
        background: `repeating-conic-gradient(
              from var(--a),
              ${primaryPalette[50]} 0%,
              ${primaryPalette[300]} 16.666%,
              ${primaryPalette[600]} 33.333%,
              ${primaryPalette[800]} 50%,
              ${primaryPalette[600]} 66.666%,
              ${primaryPalette[300]} 83.333%,
              ${primaryPalette[50]} 100%
            )`,
      },
    },
  },
};

const components: CssVarsThemeOptions['components'] = {
  JoyLink: {
    defaultProps: {
      color: 'neutral',
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        borderRadius: 0,
        textDecorationSkipInk: 'auto',
        textDecoration: 'underline',
        textUnderlineOffset: ownerState.underline === 'always' ? '0.2em' : '0.3em',
        textDecorationColor:
          ownerState.underline === 'always' ? 'currentColor' : 'rgba(255, 255, 255, 0)',
        transition: '0.2s color, 0.2s text-decoration-color, 0.1s text-underline-offset',
        transitionTimingFunction: 'ease-in-out',

        '&:hover': {
          textUnderlineOffset: '0.2em',

          ...(ownerState.color === 'primary' && {
            color: 'var(--variant-plainColor, rgba(var(--joy-palette-primary-mainChannel) / 0.7))',

            ...(ownerState.underline !== 'none' && {
              textDecorationColor:
                'var(--variant-plainColor, rgba(var(--joy-palette-primary-mainChannel) / 0.7))',
            }),
          }),

          ...(ownerState.color === 'neutral' && {
            color: 'var(--variant-plainColor, rgba(var(--joy-palette-neutral-mainChannel) / 0.7))',

            ...(ownerState.underline !== 'none' && {
              textDecorationColor:
                'var(--variant-plainColor, rgba(var(--joy-palette-neutral-mainChannel) / 0.7))',
            }),
          }),
        },
      }),
    },
  },
  JoyTypography: {
    defaultProps: {
      lineHeight: 'lg',
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        // h1
        ...(ownerState.level === 'h1' && {
          marginBottom: theme.spacing(2),
          lineHeight: theme.vars.lineHeight.lg,
        }),
        // h2
        ...(ownerState.level === 'h2' && {
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
          paddingBottom: theme.spacing(0.3),
          color: theme.palette.text.secondary,
          backgroundSize: '1px 1em',
          textShadow: '-2px -2px white, -2px 2px white, 2px -2px white, 2px 2px white',
          boxShadow: `inset 0 -0.175em white, inset 0 -0.2em ${theme.palette.divider}`,
        }),
        // h3
        ...(ownerState.level === 'h3' && {
          color: theme.palette.text.tertiary,
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(1),
        }),
      }),
    },
  },
};

const theme = extendTheme({
  ...typography,
  colorSchemes,
  components,
});

export default theme;
