import { MDXProvider } from '@mdx-js/react';
import { Box, Link, Typography } from '@mui/joy';
import { Link as GatsbyLink } from 'gatsby';
import React, { PropsWithChildren } from 'react';
import { FontFamily } from '../../styles/theme';

const MarkdownProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <MDXProvider
    components={{
      Box,
      a: ({ href, children }) => (
        <Link component={GatsbyLink} to={href!} color="primary">
          {children}
        </Link>
      ),
      p: ({ children }) => (
        <Typography level="body-lg" mb={2}>
          {children}
        </Typography>
      ),
      ul: ({ children }) => (
        <Box component="ul" p={0} mb={1} sx={{ listStylePosition: 'inside' }}>
          {children}
        </Box>
      ),
      li: ({ children }) => (
        <Box component="li" ml={1} mt={0.5}>
          <Typography component="p" level="body-lg">
            {children}
          </Typography>
        </Box>
      ),
      h1: ({ children }) => (
        <Typography level="h1" component="h1">
          {children}
        </Typography>
      ),
      h2: ({ children }) => (
        <Typography level="h2" component="h2">
          {children}
        </Typography>
      ),
      blockquote: ({ children }) => (
        <Box
          component="blockquote"
          sx={(theme) => ({
            pl: 1,
            borderLeft: `4px solid`,
            borderLeftColor: 'primary.plainColor',
            ml: 0,
          })}
        >
          {children}
        </Box>
      ),
      hr: () => (
        <Box
          component="hr"
          sx={{
            overflow: 'visible',
            padding: 0,
            border: 'none',
            borderTopStyle: 'double',
            borderTopWidth: '3px',
            borderColor: 'divider',
            color: 'divider',
            textAlign: 'center',
            height: 0,
            my: '1rlh',
            position: 'relative',

            '&::after': {
              content: "'❁'",
              fontFamily: FontFamily.Alegreya,
              display: 'inline-block',
              position: 'absolute',
              fontSize: '2.5em',
              top: '50%',
              left: '50%',
              transform: 'translateX(-50%) translateY(-50%)',
              padding: '0 0.25em',
              background: 'white',
            },
          }}
        />
      ),
      img: ({ children, ...props }) => {
        return (
          <Box
            mb={1}
            sx={{
              '& img': {
                maxWidth: '100%',
              },
            }}
          >
            <figure>
              <img {...props} />
              {props.title && (
                <Box component="figcaption" textAlign="center">
                  <Typography level="body-sm">{props.title}</Typography>
                </Box>
              )}
            </figure>
          </Box>
        );
      },
    }}
  >
    {children}
  </MDXProvider>
);

export default MarkdownProvider;
