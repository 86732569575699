import { Box, Container, Link, Stack, Typography } from '@mui/joy';
import { visuallyHidden } from '@mui/utils';
import { Link as GatsbyLink, PageProps } from 'gatsby';
import React from 'react';
import useSiteMetadata from '../hooks/useSiteMetadata';
import { FontFamily, Weight } from '../styles/theme';

export interface HeaderProps {
  location?: PageProps['location'];
}

const navigationLinks = [
  {
    name: 'Writing',
    link: '/writing',
  },
  // {
  //   name: 'Projects',
  //   link: '/creating',
  // },
];

const Header: React.FC<HeaderProps> = ({ location }) => {
  const metadata = useSiteMetadata();

  return (
    <Container maxWidth="xl" component="header">
      <Stack
        direction={['column', 'row']}
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        pt={4}
        pb={4}
      >
        <Box flex={1} />
        <Typography
          component="h1"
          fontSize="48px"
          fontFamily={FontFamily.StyleScript}
          fontWeight={Weight.xs}
          lineHeight="1rlh"
        >
          <Link
            component={GatsbyLink}
            to="/"
            color="primary"
            underline="none"
            sx={{ '&:hover': { boxShadow: 'none' } }}
          >
            Mike Fowler
          </Link>
        </Typography>

        <Box flex={1} component="nav" sx={metadata.settings?.hideNavigation ? visuallyHidden : {}}>
          <Typography level="h2" sx={visuallyHidden}>
            Main navigation
          </Typography>
          <Stack direction="row" margin={0} gap={2} component="menu" justifyContent="flex-end">
            {navigationLinks?.map(({ name, link }) => (
              <Box component="li" key={`${name}-${link}`} sx={{ listStyle: 'none' }}>
                <Link component={GatsbyLink} to={link} color="neutral" fontSize="sm">
                  <Typography fontFamily="display">{name}</Typography>
                </Link>
              </Box>
            ))}
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
};

export default Header;
