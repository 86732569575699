import chroma from 'chroma-js';

export const keys = ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900'] as const;

const generatePalette = (scale: string[], count = 10) => {
  const colors = chroma
    .scale(scale) // Comment to hold these line breaks
    .mode('lch')
    .correctLightness()
    .colors(count);

  return keys.reduce(
    (acc, key, i) => {
      acc[key] = colors[i];
      return acc;
    },
    {} as Record<(typeof keys)[number], string>,
  );
};

export default generatePalette;
